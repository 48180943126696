import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';

import ScrollMagic from 'scrollmagic';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

import Title from './Title';
import ListItem from './ListItem';
import { dataSkills } from './../data/data';

const TitleContainer = styled.section.attrs(() => ({
  className: 'js-skills-title',
}))`
  /* padding-top: 144px; */
  padding-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  overflow: hidden;

  h2 {
    margin-right: -0.9vw;
  }
`;

const ContentContainer = styled.section.attrs(() => ({
  className: 'container-fluid',
}))`
  text-align: right;
  ul {
    margin-top: 3.125rem;
    margin-right: 14vw;
  }
`;

const Skills = () => {
  useEffect(() => {
    const controller = new ScrollMagic.Controller();

    new ScrollMagic.Scene({
      triggerElement: '.js-skills-title',
      triggerHook: 0.22,
      duration: 180,
      offset: 50,
      reverse: false,
    })
      .setPin('.js-skills-title')
      // .addIndicators({name:'Skills - Title'})
      .addTo(controller);

    TweenMax.set('.js-skills-list', { opacity: 0 });

    new ScrollMagic.Scene({
      triggerElement: '.js-skills-list',
      triggerHook: 0.68,
      duration: 100,
      reverse: false,
    })
      .setTween('.js-skills-list', 1, { opacity: 1 })
      // .addIndicators({name:'Skills - List'})
      .addTo(controller);
  }, []);

  return (
    <Fragment>
      <TitleContainer>
        <Title>skills</Title>
      </TitleContainer>
      <ContentContainer>
        <div className="row">
          <div className="col-md-6 offset-md-6">
            <ul className="js-skills-list">
              {dataSkills.map((el, index) => (
                <ListItem key={index}>{el}</ListItem>
              ))}
            </ul>
          </div>
        </div>
      </ContentContainer>
    </Fragment>
  );
};

export default Skills;
