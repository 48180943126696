import styled from 'styled-components';
import image from './intro1.jpg';

const IntroImg = styled.div`
  display: none;

  @media (min-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    opacity: ${(props) => (props.img ? 0.4 : 0)};
    transition: all 200ms ease-in-out;
  }
`;

export default IntroImg;
