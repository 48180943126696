import React, { Fragment } from 'react';
import { createGlobalStyle } from 'styled-components';

import Intro from './components/Intro';
import Lab from './components/Lab';
import Skills from './components/Skills';
import Tools from './components/Tools';
import Courses from './components/Courses';
import Contact from './components/Contact';

const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: #000000;
    --white: #ffffff;
    --primary-font-family: 'Libre Caslon Text';
    --secondary-font-family: 'Raleway';
  }

  body {
    font-family: var(--secondary-font-family), serif;
  }

  ul {
    padding-left: 0;
    margin: 0;
    list-style-type: none;
  }
`;

const App = () => {
  return (
    <Fragment>
      <GlobalStyle />
      <Intro />
      {/*<Lab />*/}
      <Skills />
      <Tools />
      <Courses />
      <Contact />
    </Fragment>
  );
};

export default App;
