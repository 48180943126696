import styled from 'styled-components';

const SocialList = styled.ul.attrs(() => ({
  className: 'js-socialList',
}))`
  list-style-type: none;
  opacity: 1;

  display: flex;
  flex-direction: row;
  margin-top: 2rem;

  li {
    margin-right: 1rem;
  }

  @media (min-width: 768px) {
    justify-content: space-between;
    width: 325px;

    position: absolute;
    top: 50%;
    right: 31px;
    transform: rotate(270deg) translateX(50%);
    transform-origin: right center;

    li {
      margin-right: 0;
    }
  }
`;

export default SocialList;
