import React, { useEffect } from 'react';
import styled from 'styled-components';

import { TweenMax } from 'gsap';
import ScrollMagic from 'scrollmagic';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

const Container = styled.footer.attrs(() => ({
  className: 'js-contact',
}))`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: var(--primary-color);
`;

const Email = styled.p.attrs(() => ({
  className: 'js-email',
}))`
  color: var(--white);
  font-family: var(--secondary-font-family), sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;

  @media (min-width: 768px) {
    font-size: 3.75rem;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    font-size: 2.4rem;
  }
`;

const Contact = () => {
  useEffect(() => {
    const controller = new ScrollMagic.Controller();

    TweenMax.set('.js-email', { y: 100, opacity: 0 });

    new ScrollMagic.Scene({
      triggerElement: '.js-contact',
      triggerHook: 0.25,
      duration: 200,
      reverse: false,
    })
      .setTween(
        TweenMax.to('.js-email', 1, {
          y: 0,
          opacity: 1,
          ease: Power1.easeInOut,
        })
      )
      // .addIndicators({name:'Contact', colorStart: 'orange'})
      .addTo(controller);
  }, []);

  return (
    <Container>
      <Email>hello@danielsegura.dev</Email>
    </Container>
  );
};

export default Contact;
