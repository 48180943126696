import styled from 'styled-components';

const MainText = styled.h1`
  color: var(--primary-color);
  font-family: var(--primary-font-family), serif;
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 1.65;
  margin: 0;
  padding: 0;
  opacity: 1;
  transform: translateY(10px);

  @media (min-width: 768px) {
    font-size: 5.625rem;
    line-height: 1.4;
  }
  @media (min-width: 768px) and (max-width: 992px) and (orientation: portrait) {
    font-size: 3rem;
  }
  @media (min-width: 768px) and (max-width: 992px) and (orientation: landscape) {
    font-size: 3.625rem;
  }
`;

export default MainText;
