import styled from 'styled-components';

const Title = styled.h2`
  color: var(--primary-color);
  font-family: var(--primary-font-family), serif;
  font-size: 6rem;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  padding: 0;

  @media (min-width: 768px) {
    font-size: 8.5rem;
  }
  @media (min-width: 992px) {
    font-size: 12.5rem;
  }
`;

export default Title;
