export const dataLab = [
  {
    title: 'React Components Library',
    description: 'A themeable react components library.',
    github: 'https://github.com/daniseguraf/react-components-library',
  },
];

export const dataSkills = [
  'Design System',
  'JavaScript',
  'React',
  'React Hooks',
  'React Context API',
  'Styled Components',
  'HTML',
  'CSS',
  'Sass',
  'npm',
  'yarn',
  'lerna',
  'monorepos',
  'Jest',
  'Git',
  'Stencil.js',
  'Bootstrap',
  'Web Components',
];

export const dataTools = [
  'Visual Studio Code',
  'GitHub',
  'Figma',
  'Storybook',
  'Jira',
  'Zeplin',
  'Framer',
  'Notion',
  'CircleCI',
  'Chromatic',
  'Bitbucket',
  'Web Design',
];

export const dataCourses = [
  {
    title: 'The Complete Guide to Advanced React Component Patterns',
    description: 'Udemy, September 2020',
  },
  {
    title: 'Design Systems with React & Storybook',
    description: 'FrontendMasters, August 2020',
  },
  {
    title: 'Firebase with React, v2',
    description: 'FrontendMasters, July 2020',
  },
  {
    title: 'Intermediate React, v2',
    description: 'FrontendMasters, July 2020',
  },
  {
    title: 'Complete Intro to React, v5',
    description: 'FrontendMasters, March 2020',
  },
  {
    title: 'JavaScript: From Fundamentals to Functional JS, v2',
    description: 'FrontendMasters, 2020',
  },
  {
    title: 'JavaScript: The Hard Parts v2',
    description: 'FrontendMasters, 2019',
  },
  {
    title: 'React.js',
    description: 'Área 51 Training Center, 2019',
  },
  {
    title: 'Web Components & Stencil.js - Build Custom HTML Elements',
    description: 'Udemy, 2019',
  },
];
