import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TweenMax } from 'gsap';
import ScrollMagic from 'scrollmagic';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
// import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";

import Title from './Title';

import { dataLab } from '../data/data';
import GridItem from './GridItem';

const Container = styled.section.attrs(() => ({
  id: 'lab',
  className: 'container-fluid',
}))`
  padding-bottom: 163px;
  transition: all 250ms ease-in-out;

  h2 {
    margin-top: 250px;
    margin-left: -1.3vw;
    margin-bottom: 2.56rem;
    transform: translateY(-80px);
  }

  .card {
    word-wrap: break-word;
    margin-bottom: 1.5rem;
    padding: 0 0.5rem;
  }

  .col-md-4:first-of-type {
    /* background: red; */
    transform: translateY(100px);
  }

  .col-md-4:nth-of-type(2) {
    /* background: blue; */
    transform: translateY(-50px);
  }

  .col-md-4:nth-of-type(3) {
    /* background: orange; */
    transform: translateY(-150px);
  }
`;
// Divide dataLab array in three arrays
const length = dataLab.length;

let dataFirstPart = [];
let dataSecondtPart = [];
let dataThirdtPart = [];

let i;
for (i = 0; i < length / 3; i++) {
  dataFirstPart.push(dataLab[i]);
}

let j;
for (j = i; j < length - length / 3; j++) {
  dataSecondtPart.push(dataLab[j]);
}

let k;
for (k = j; k < length; k++) {
  dataThirdtPart.push(dataLab[k]);
}

function Lab() {
  useEffect(() => {
    const controller = new ScrollMagic.Controller();

    // Title
    new ScrollMagic.Scene({
      triggerElement: '.card-columns',
      triggerHook: 0.8,
      offset: -170,
      duration: 300,
      reverse: false,
    })
      .setPin('h2')
      // .addIndicators({name:'Pin - Lab Title'})
      .addTo(controller);

    // Cards
    TweenMax.set('.col-md-4:first-of-type', { y: 0 });
    TweenMax.set('.col-md-4:nth-of-type(2)', { y: 0 });
    TweenMax.set('.col-md-4:nth-of-type(3)', { y: 0 });

    // Left Column
    new ScrollMagic.Scene({
      triggerElement: '.card-columns',
      triggerHook: 0.75,
      duration: 210,
      reverse: false,
    })
      .setTween(
        TweenMax.to('.col-md-4:first-of-type', 1, {
          y: 100,
          ease: Linear.easeNone,
        })
      )
      // .addIndicators({name:'Left - Cards', colorStart: 'orange'})
      .addTo(controller);

    // Center Column
    new ScrollMagic.Scene({
      triggerElement: '.card-columns',
      triggerHook: 0.78,
      duration: 210,
      reverse: false,
    })
      .setTween(
        TweenMax.to('.col-md-4:nth-of-type(2)', 1, {
          y: -50,
          ease: Linear.easeNone,
        })
      )
      // .addIndicators({name:'Center - Cards', colorStart: 'orange'})
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: '.card-columns',
      triggerHook: 0.8,
      duration: 220,
      reverse: false,
    })
      .setTween(
        TweenMax.to('.col-md-4:nth-of-type(3)', 1, {
          y: -150,
          ease: Linear.easeNone,
        })
      )
      // .addIndicators({name:'CARDS'})
      .addTo(controller);
  }, []);

  return (
    <Container>
      <Title>projects</Title>

      <div className="row">
        <div className="col-md-10 offset-md-1">
          <div className="row card-columns">
            <div className="col-md-4">
              {dataFirstPart.map((el, index) => (
                <div key={index} className="card">
                  <GridItem {...el} />
                </div>
              ))}
            </div>
            <div className="col-md-4">
              {dataSecondtPart.map((el, index) => (
                <div key={index} className="card">
                  <GridItem {...el} />
                </div>
              ))}
            </div>
            <div className="col-md-4">
              {dataThirdtPart.map((el, index) => (
                <div key={index} className="card">
                  <GridItem {...el} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Lab;
