import React, { useState, useEffect } from 'react';
import { TweenMax } from 'gsap';
import styled from 'styled-components';

import MainText from './MainText';
import SocialList from './SocialList';
import ListItem from './ListItem';
import Link from './Link';
import IntroImg from './IntroImg/IntroImg';

const Container = styled.section.attrs(() => ({
  className: 'container-fluid',
}))`
  padding: 0 1.5rem;
  position: relative;
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: 992px) {
    display: flex;
    height: 100vh;
    width: 100%;
  }

  .row {
    /* align-items: initial; */
    margin-top: 16vh;
    align-items: center;

    @media (max-width: 992px) and (orientation: landscape) {
      margin-top: 10%;
    }
    @media (min-width: 768px) and (max-width: 992px) and (orientation: portrait) {
      margin-top: 12%;
    }
    @media (min-width: 992px) {
      margin-top: 0;
    }

    .js-socialList {
      @media (max-width: 768px) {
        display: initial;
      }
    }
  }

  span {
    font-weight: 700;
    outline: none;
    cursor: default;
  }

  .arrow {
    margin-top: 1.2rem;
    width: 1.4rem;

    @media (min-width: 768px) {
      margin-top: 1.5rem;
      width: 1.9rem;
    }
  }
`;

const Social = (
  <SocialList>
    <ListItem itemSmall>
      <Link href={'https://www.linkedin.com/in/daniel-segura-fang/'}>
        linkedln
      </Link>
    </ListItem>
    <ListItem itemSmall>
      <Link href={'https://www.instagram.com/daniseguraf/'}>instagram</Link>
    </ListItem>
    <ListItem itemSmall>
      <Link href={'https://github.com/daniseguraf'}>github</Link>
    </ListItem>
  </SocialList>
);

function Intro() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const h1 = document.getElementsByTagName('h1');
    const socialList = document.querySelector('.js-socialList');
    const arrow = document.querySelector('.arrow');

    TweenMax.set(h1, { y: 50, opacity: 0 });
    TweenMax.set(socialList, { opacity: 0 });
    TweenMax.set(arrow, { y: -10, opacity: 0 });

    const tl = new TimelineMax({ delay: 0.35 });

    tl.to(h1, 0.6, { y: 0, opacity: 1 })
      .to(socialList, 0.5, { opacity: 1 }, '-=0.2')
      .to(arrow, 0.6, { y: 0, opacity: 1 }, '-=0.4');
  }, []);

  return (
    <Container>
      <IntroImg img={isVisible} />

      <div className="row">
        <div className="col-12 col-md-9 offset-md-1 col-xl-8 offset-xl-1">
          <MainText>
            I’m{' '}
            <span
              onMouseEnter={() => setIsVisible(!isVisible)}
              onMouseLeave={() => setIsVisible(!isVisible)}>
              Daniel Segura
            </span>
            , UI developer with good taste for design, based in Lima - Perú.
          </MainText>

          {window.addEventListener('resize', () => {
            // console.log(window.innerWidth);
          })}
          <img
            className="arrow"
            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9ImRvd24tYXJyb3ctbG93LWJlbG93IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA4LjcwNiAxNS42OTg7IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA4LjcwNiAxNS42OTgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwb2x5Z29uIHBvaW50cz0iOCwxMC42NDYgNC44NTMsMTMuNzg2IDQuODUzLDAgMy44NTMsMCAzLjg1MywxMy43ODYgMC43MDYsMTAuNjQ2IDAsMTEuMzU0IDQuMzUzLDE1LjY5OCA4LjcwNiwxMS4zNTQgIi8+PC9zdmc+"
            alt=""
          />
        </div>
      </div>

      {Social}
    </Container>
  );
}

export default Intro;
