import React from 'react';
import { render } from 'react-dom';
import WebFont from 'webfontloader';

import 'normalize.css';
import './node_modules/bootstrap/dist/css/bootstrap-grid.min.css';

import App from './src/App';

WebFont.load({
  google: {
    families: ['Libre Caslon Text', 'Raleway:300,400,700', 'sans-serif'],
  },
});

render(<App />, document.getElementById('root'));
