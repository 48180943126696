import React, { Fragment } from 'react';
import Link from './Link';
import styled from 'styled-components';

const Content = styled.article`
  width: 100%;
  height: auto;

  h3,
  p,
  a {
    color: var(--primary-color);
    font-family: var(--secondary-font-family);
    line-height: 1.5;
    margin: 0;
    padding: 0;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.3125rem;
  }

  p,
  a {
    font-size: 1.125rem;
    font-weight: 300;
  }
`;

const GridItem = ({ title, description, demo, github, web }) => (
  <Content>
    <h3>{title}</h3>
    <p>{description}</p>
    <p>
      {demo || github ? (
        <Fragment>
          <Link href={github}>GitHub</Link> {demo ? '/ ' : null}
          {demo && <Link href={demo}>Demo</Link>}
        </Fragment>
      ) : null}
    </p>
  </Content>
);

export default GridItem;
