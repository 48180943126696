import styled from 'styled-components';

const Link = styled.a`
  color: var(--primary-color);
  font-family: var(--secondary-font-family), sans-serif;
  padding-bottom: 4px;
  text-decoration: none;
  border-bottom: 4px solid transparent;
  transition: all 250ms ease;

  &:visited {
    color: var(--primary-color);
    padding-bottom: 4px;
    text-decoration: none;
    border-bottom: 4px solid transparent;
  }

  &:hover,
  &:active {
    border-bottom: 4px solid var(--primary-color);
    text-decoration: none;
  }
`;

export default Link;
