import React, { useEffect } from 'react';
import styled from 'styled-components';

import { TweenMax } from 'gsap';
import ScrollMagic from 'scrollmagic';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

import Title from './Title';
import GridItem from './GridItem';
import { dataCourses } from './../data/data';

const Container = styled.section.attrs(() => ({
  className: 'container-fluid js-courses',
}))`
  margin-top: 285px;
  margin-bottom: 16rem;

  h2 {
    margin-left: 5.5vw;
  }
`;

const GridItems = styled.div.attrs(() => ({
  className: 'js-courses-list',
}))`
  margin-top: 3.125rem;

  article {
    margin-bottom: 1.85rem;
  }
`;

const Courses = () => {
  useEffect(() => {
    const controller = new ScrollMagic.Controller();

    TweenMax.set('.js-courses-list', { x: 80, opacity: 0 });

    new ScrollMagic.Scene({
      triggerElement: '.js-courses',
      triggerHook: 0.3,
      duration: 200,
      reverse: false,
    })
      .setTween(
        TweenMax.to('.js-courses-list', 1, {
          x: 0,
          opacity: 1,
          ease: Power1.easeInOut,
        })
      )
      // .addIndicators({name:'Coursesss'})
      .addTo(controller);
  }, []);

  return (
    <Container>
      <div className="row">
        <div className="col-md-8 offset-md-3">
          <Title>education</Title>

          <GridItems>
            {dataCourses.map((el, index) => (
              <GridItem key={index} {...el} />
            ))}
          </GridItems>
        </div>
      </div>
    </Container>
  );
};

export default Courses;
