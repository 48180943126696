import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';

import ScrollMagic from 'scrollmagic';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

import Title from './Title';
import ListItem from './ListItem';
import { dataTools } from './../data/data';

const TitleContainer = styled.section.attrs(() => ({
  className: 'js-tools-title',
}))`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 0;

  h2 {
    margin-left: -0.45vw;
  }
`;

const ContentContainer = styled.section.attrs(() => ({
  className: 'container-fluid',
}))`
  ul {
    margin-top: 3.125rem;
    margin-left: 12vw;
  }
`;

const Tools = () => {
  useEffect(() => {
    const controller = new ScrollMagic.Controller();

    // Title
    new ScrollMagic.Scene({
      triggerElement: '.js-tools-title',
      triggerHook: 0.4,
      duration: 180,
      offset: 50,
      reverse: false,
    })
      .setPin('.js-tools-title')
      // .addIndicators({name:'Tools - Title'})
      .addTo(controller);

    TweenMax.set('.js-tools-list', { opacity: 0 });

    new ScrollMagic.Scene({
      triggerElement: '.js-tools-list',
      triggerHook: 0.68,
      duration: 100,
      reverse: false,
    })
      .setTween('.js-tools-list', 1, { opacity: 1 })
      // .addIndicators({name:'Tools - List'})
      .addTo(controller);
  }, []);

  return (
    <Fragment>
      <TitleContainer>
        <Title>tools</Title>
      </TitleContainer>
      <ContentContainer>
        <div className="row">
          <div className="col-md-6">
            <ul className="js-tools-list">
              {dataTools.map((el, index) => (
                <ListItem key={index}>{el}</ListItem>
              ))}
            </ul>
          </div>
        </div>
      </ContentContainer>
    </Fragment>
  );
};

export default Tools;
