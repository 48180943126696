import styled, { css } from 'styled-components';

const ListItem = styled.li`
  color: var(--primary-color);
  font-family: var(--secondary-font-family);
  font-size: 1.3rem;
  line-height: 2.2;
  margin: 0;
  padding: 0;

  @media (min-width: 768px) {
    font-size: 1.4rem;
    line-height: 2.2;
  }

  ${(props) =>
    props.itemSmall &&
    css`
      font-size: 1.125rem !important;
      font-weight: 300;
      letter-spacing: 0.099rem;
      line-height: 1 !important;
    `}
`;

export default ListItem;
